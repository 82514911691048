<template>
    <div class="tw-space-y-2">
        <h4
            class="tw-mb-0 tw-p-1 tw-uppercase tw-font-semibold tw-text-gray-400 tw-underline tw-mt-3 sm:tw-mt-0 lg:tw-mt-0"
        >
            {{ $t("driverProfile.vehicleLicense") }}
        </h4>
        <div class="row tw-space-y-2 sm:tw-space-y-0">
            <div class="col-md-6 tw-space-y-1">
                <label class="required">{{
                    $t("driverProfile.platNumber")
                }}</label>
                <input
										v-model.trim="model.plat_number"
                    type="text"
                    class="form-control"
										:class="{'is-invalid': errors.has('plat_number')}"
                    :placeholder="$t('driverProfile.platNumber')"
                />
								<div class="invalid-feedback" v-if="errors.has('plat_number')">
										{{ errors.first('plat_number') }}
								</div>
            </div>
            <div class="col-md-6 tw-space-y-1">
                <label class="required">{{
                    $t("driverProfile.vehicleType")
                }}</label>
                <a-select
										v-model.number="model.vehicle_type_id"
                    class="tw-w-full"
                    :options="vehicleTypeList"
                    :placeholder="$t('driverProfile.vehicleType')"
                ></a-select>
								<div class="tw-text-red-600" v-if="errors.has('vehicle_type_id')">
										{{ errors.first('vehicle_type_id') }}
								</div>								
            </div>
        </div>
        <div class="row tw-space-y-2 sm:tw-space-y-0">
            <div class="col-md-6 tw-space-y-1">
                <label>{{
                    $t("driverProfile.driverLicenseNo")
                }}</label>
                <input
										v-model.trim="model.driver_license_no"
                    type="text"
                    class="form-control"
										:class="{'is-invalid': errors.has('driver_license_no')}"
                    :placeholder="$t('driverProfile.driverLicenseNo')"
                />
								<div class="invalid-feedback" v-if="errors.has('driver_license_no')">
										{{ errors.first('driver_license_no') }}
								</div>
            </div>
            <div class="col-md-6 tw-space-y-1">
                <label>{{
                    $t("driverProfile.driverIssueDate")
                }}</label>
                <a-date-picker
										v-model="model.driver_license_issue_date"
										format="DD-MM-YYYY"
										valueFormat="DD-MM-YYYY"
                    class="tw-w-full"
                    :placeholder="$t('driverProfile.driverIssueDate')"
                ></a-date-picker>
								<div class="tw-text-red-600" v-if="errors.has('driver_license_issue_date')">
										{{ errors.first('driver_license_issue_date') }}
								</div>										
            </div>
        </div>
        <div class="row tw-space-y-2 sm:tw-space-y-0">
            <div class="col-md-6 tw-space-y-1">
                <label>{{
                    $t("driverProfile.driverExpiredDate")
                }}</label>
                <a-date-picker
										v-model="model.driver_license_expiry_date"
										format="DD-MM-YYYY"
										valueFormat="DD-MM-YYYY"
                    class="tw-w-full"
                    :placeholder="$t('driverProfile.driverExpiredDate')"
                ></a-date-picker>
								<div class="tw-text-red-600" v-if="errors.has('driver_license_expiry_date')">
										{{ errors.first('driver_license_expiry_date') }}
								</div>										
            </div>
            <div class="col-md-6 tw-space-y-1">
                <label>{{
                    $t("driverProfile.vehicleColor")
                }}</label>
                <input
										v-model.trim="model.vehicle_color"
                    type="text"
                    class="form-control"
										:class="{'is-invalid': errors.has('vehicle_color')}"
                    :placeholder="$t('driverProfile.vehicleColor')"
                />
								<div class="invalid-feedback" v-if="errors.has('vehicle_color')">
										{{ errors.first('vehicle_color') }}
								</div>									
            </div>
        </div>
        <div class="row tw-space-y-2 sm:tw-space-y-0">
            <div class="col-md-6 tw-space-y-1">
                <label>{{
                    $t("driverProfile.guarantor1")
                }}</label>
                <input
										v-model.trim="model.guarantor1"
                    type="text"
                    class="form-control"
										:class="{'is-invalid': errors.has('guarantor1')}"
                    :placeholder="$t('driverProfile.guarantor1')"
                />
								<div class="invalid-feedback" v-if="errors.has('guarantor1')">
										{{ errors.first('guarantor1') }}
								</div>								
            </div>
            <div class="col-md-6 tw-space-y-1">
                <label>{{
                    $t("driverProfile.guarantorPhone1")
                }}</label>
                <input
										v-model="model.guarantor1_phone"
                    type="text"
                    class="form-control"
										:class="{'is-invalid': errors.has('guarantor1_phone')}"
                    :placeholder="$t('driverProfile.guarantorPhone1')"
                />
								<div class="invalid-feedback" v-if="errors.has('guarantor1_phone')">
										{{ errors.first('guarantor1_phone') }}
								</div>	
            </div>
        </div>
        <div class="row tw-space-y-2 sm:tw-space-y-0">
            <div class="col-md-6 tw-space-y-1">
                <label>{{ $t("driverProfile.guarantor2") }}</label>
                <input
										v-model.trim="model.guarantor2"
                    type="text"
                    class="form-control"
										:class="{'is-invalid': errors.has('guarantor2')}"
                    :placeholder="$t('driverProfile.guarantor2')"
                />
								<div class="invalid-feedback" v-if="errors.has('guarantor2')">
										{{ errors.first('guarantor2') }}
								</div>
            </div>
            <div class="col-md-6 tw-space-y-1">
                <label>{{ $t("driverProfile.guarantorPhone2") }}</label>
                <input
										v-model="model.guarantor2_phone"
                    type="text"
                    class="form-control"
										:class="{'is-invalid': errors.has('guarantor2_phone')}"
                    :placeholder="$t('driverProfile.guarantorPhone2')"
                />
								<div class="invalid-feedback" v-if="errors.has('guarantor2_phone')">
										{{ errors.first('guarantor2_phone') }}
								</div>	
            </div>
        </div>
    </div>
</template>

<script>
import { Errors } from "form-backend-validation";
import { mapState } from 'vuex';
export default {
    name: "vehicleLicense",
    props: ["value", "validate"],
    data() {
        return {
            errors: new Errors(),
            model: {}
        };
    },
    created() {
        this.model = this.value;
    },
		computed: {
			...mapState("driver/driverProfile", ['formModels']),
        vehicleTypeList() {
            if (!this.formModels.vehicleType) return [];

            return this.formModels.vehicleType.map(el => ({
                value: el.vehicle_type_id,
                label: el.vehicle_types
            }));
        }
    },
    watch: {
        validate: function(error) {
            this.errors = new Errors(error.errors);
        }
    }
};
</script>

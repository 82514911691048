<template>
    <div class="tw-space-y-2">
        <h4
            class="tw-mt-3 tw-mb-0 tw-p-1 tw-uppercase tw-font-semibold tw-text-gray-400 tw-underline"
        >
            {{ $t("driverProfile.bankAccount") }}
        </h4>
        <div class="row">
            <div class="col-md-6 tw-space-y-1">
                <label>{{ $t("driverProfile.bankName") }}</label>
                <a-select
                    v-model.number="model.bank_id"
										:options="bankProfileList"
										showSearch
										:filterOption="
												(input, option) =>
														option.componentOptions.children[0].text
																.toLowerCase()
																.indexOf(input.toLowerCase()) >= 0
										"										
                    class="tw-w-full"
										:placeholder=" $t('driverProfile.bankName')"
                ></a-select>
                <div class="tw-text-red-600" v-if="errors.has('bank_id')">
                    {{ errors.first("bank_id") }}
                </div>
            </div>
            <div class="col-md-6 tw-space-y-1">
                <label>{{ $t("driverProfile.accountNumber") }}</label>
                <input
                    v-model="model.bank_acc_number"
                    type="text"
                    class="form-control"
										:placeholder=" $t('driverProfile.accountNumber')"
                    :class="{ 'is-invalid': errors.has('bank_acc_number') }"
                />
                <div
                    class="invalid-feedback"
                    v-if="errors.has('bank_acc_number')"
                >
                    {{ errors.first("bank_acc_number") }}
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6 tw-space-y-1">
                <label>{{ $t("driverProfile.accountName") }}</label>
                <input
                    v-model="model.bank_acc_name"
                    type="text"
                    :class="{ 'is-invalid': errors.has('bank_acc_number') }"
                    class="form-control"
										:placeholder=" $t('driverProfile.accountName')"
                />
                <div
                    class="invalid-feedback"
                    v-if="errors.has('bank_acc_name')"
                >
                    {{ errors.first("bank_acc_name") }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Errors } from "form-backend-validation";
import { mapState } from 'vuex';
export default {
    name: "bankAccount",
    props: ["value", "validate"],
    data() {
        return {
            errors: new Errors(),
            model: {}
        };
    },
    created() {
        this.model = this.value;
    },
    computed: {
			...mapState("driver/driverProfile", ['formModels']),
        bankProfileList() {
            if (!this.formModels.bankProfile) return [];

            return this.formModels.bankProfile.map(el => ({
                value: el.bank_id,
                label: el.bank_name
            }));
        }
    },
    watch: {
        validate: function(error) {
            this.errors = new Errors(error.errors);
        }
    }
};
</script>

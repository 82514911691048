<template>
    <ts-panel>
        <ts-panel-wrapper class="tw-space-y-2">
            <h4
                class="tw-mb-0 tw-p-1 tw-uppercase tw-font-semibold tw-text-gray-400 tw-underline"
            >
                {{ $t('driverProfile.fee') }}
            </h4>
            <div class="row tw-space-y-2 sm:tw-space-y-0">
                <div class="col-md-2 col-sm-12 tw-space-y-1">
                    <div class="row">
                        <label class="required">{{
                            $t('driverProfile.pickUpFee')
                        }}</label>
                        <input
                            v-model.number="model.pickup_fee"
                            type="number"
                            :placeholder="$t('driverProfile.pickUpFee')"
                            step="any"
                            class="form-control"
                        />
                        <div
                            class="tw-text-red-600"
                            v-if="errors.has('pickup_fee')"
                        >
                            {{ errors.first('pickup_fee') }}
                        </div>
                    </div>
                    <div class="row">
                        <ts-checkbox v-model="model.is_global_fee">{{
                            $t('driverProfile.isGlobalFee')
                        }}</ts-checkbox>
                    </div>
                </div>
                <div class="col-md-10 col-sm-12 tw-space-y-1">
                    <table class="tw-w-full tw-mb-5">
                        <thead>
                            <tr>
                                <th
                                    class="tw-uppercase tw-bg-gray-100 tw-whitespace-nowrap tw-px-3 tw-py-2 tw-w-4"
                                >
                                    {{ $t('globalDriverFee.no') }}
                                </th>
                                <th
                                    class="tw-uppercase tw-bg-gray-100 tw-whitespace-nowrap tw-px-3 tw-py-2"
                                >
                                    {{ $t('globalDriverFee.numPackageFrom') }}
                                </th>
                                <th
                                    class="tw-uppercase tw-bg-gray-100 tw-whitespace-nowrap tw-px-3 tw-py-2"
                                >
                                    {{ $t('globalDriverFee.numPackageTo') }}
                                </th>
                                <th
                                    class="tw-uppercase tw-bg-gray-100 tw-whitespace-nowrap tw-px-3 tw-py-2"
                                >
                                    {{
                                        $t(
                                            'globalDriverFee.expressVipDeliveryFee'
                                        )
                                    }}
                                </th>
                                <th
                                    class="tw-uppercase tw-bg-gray-100 tw-whitespace-nowrap tw-px-3 tw-py-2"
                                >
                                    {{
                                        $t(
                                            'globalDriverFee.expressNormalDeliveryFee'
                                        )
                                    }}
                                </th>
                                <th
                                    class="tw-uppercase tw-bg-gray-100 tw-whitespace-nowrap tw-px-3 tw-py-2"
                                >
                                    {{
                                        $t(
                                            'globalDriverFee.standardVipDeliveryFee'
                                        )
                                    }}
                                </th>
                                <th
                                    class="tw-uppercase tw-bg-gray-100 tw-whitespace-nowrap tw-px-3 tw-py-2"
                                >
                                    {{
                                        $t(
                                            'globalDriverFee.standardNormalDeliveryFee'
                                        )
                                    }}
                                </th>
                                <th
                                    class="tw-w-8 tw-py-2 tw-px-1 tw-bg-gray-100"
                                    v-if="!model.is_global_fee"
                                >
                                    <a
                                        @click.prevent="addRow"
                                        href="#"
                                        class="tw-bg-blue-400 tw-px-2 tw-py-1 tw-rounded-full tw-text-white"
                                        ><i class="fa fa-plus"></i
                                    ></a>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                v-for="(l, index) in model.driver_fees"
                                :key="index"
                                class="tw-border-b"
                            >
                                <td class="tw-px-3 tw-py-2 tw-text-center">
                                    {{ index + 1 }}
                                </td>
                                <td class="tw-px-3 tw-py-2 tw-w-48">
                                    <input
                                        v-model.number="l.num_package_from"
                                        type="number"
                                        step="any"
                                        class="form-control"
                                        :disabled="model.is_global_fee"
                                        :placeholder="
                                            $t('globalDriverFee.numPackageFrom')
                                        "
                                    />
                                    <div
                                        class="tw-text-red-600 tw-text-xs"
                                        v-if="
                                            errors.has(
                                                'item_list.' +
                                                    index +
                                                    '.num_package_from'
                                            )
                                        "
                                    >
                                        {{
                                            errors.first(
                                                'item_list.' +
                                                    index +
                                                    '.num_package_from'
                                            )
                                        }}
                                    </div>
                                </td>
                                <td class="tw-px-3 tw-py-2 tw-w-48">
                                    <input
                                        v-model.number="l.num_package_to"
                                        type="number"
                                        step="any"
                                        class="form-control"
                                        :disabled="model.is_global_fee"
                                        :placeholder="
                                            $t('globalDriverFee.numPackageTo')
                                        "
                                    />
                                    <div
                                        class="tw-text-red-600 tw-text-xs"
                                        v-if="
                                            errors.has(
                                                'item_list.' +
                                                    index +
                                                    '.num_package_to'
                                            )
                                        "
                                    >
                                        {{
                                            errors.first(
                                                'item_list.' +
                                                    index +
                                                    '.num_package_to'
                                            )
                                        }}
                                    </div>
                                </td>
                                <td class="tw-px-3 tw-py-2 tw-w-48">
                                    <div class="tw-flex">
                                        <input
                                            type="number"
                                            class="tw-border tw-border-r-0 tw-rounded-r-none tw-rounded tw-py-2 tw-px-3 tw-outline-none tw-w-full"
                                            :placeholder="
                                                $t(
                                                    'globalDriverFee.expressVipDeliveryFee'
                                                )
                                            "
                                            :disabled="model.is_global_fee"
                                            v-model.number="
                                                l.express_vip_delivery_fee
                                            "
                                        />
                                        <select
                                            v-model="
                                                l.express_vip_delivery_fee_type
                                            "
                                            :disabled="model.is_global_fee"
                                            class="tw-w-12 tw-border tw-border-l-0 tw-rounded-r tw-outline-none"
                                        >
                                            <option value="$">$</option>
                                            <option value="%">%</option>
                                        </select>
                                    </div>
                                    <div
                                        class="tw-text-red-600 tw-text-xs"
                                        v-if="
                                            errors.has(
                                                'item_list.' +
                                                    index +
                                                    '.express_vip_delivery_fee'
                                            )
                                        "
                                    >
                                        {{
                                            errors.first(
                                                'item_list.' +
                                                    index +
                                                    '.express_vip_delivery_fee'
                                            )
                                        }}
                                    </div>
                                </td>
                                <td class="tw-px-3 tw-py-2 tw-w-48">
                                    <div class="tw-flex">
                                        <input
                                            type="number"
                                            class="tw-border tw-border-r-0 tw-rounded-r-none tw-rounded tw-py-2 tw-px-3 tw-outline-none tw-w-full"
                                            :placeholder="
                                                $t(
                                                    'globalDriverFee.expressNormalDeliveryFee'
                                                )
                                            "
                                            :disabled="model.is_global_fee"
                                            v-model.number="
                                                l.express_normal_delivery_fee
                                            "
                                        />
                                        <select
                                            v-model="
                                                l.express_normal_delivery_fee_type
                                            "
                                            :disabled="model.is_global_fee"
                                            class="tw-w-12 tw-border tw-border-l-0 tw-rounded-r tw-outline-none"
                                        >
                                            <option value="$">$</option>
                                            <option value="%">%</option>
                                        </select>
                                    </div>
                                    <div
                                        class="tw-text-red-600 tw-text-xs"
                                        v-if="
                                            errors.has(
                                                'item_list.' +
                                                    index +
                                                    '.express_normal_delivery_fee'
                                            )
                                        "
                                    >
                                        {{
                                            errors.first(
                                                'item_list.' +
                                                    index +
                                                    '.express_normal_delivery_fee'
                                            )
                                        }}
                                    </div>
                                </td>
                                <td class="tw-px-3 tw-py-2 tw-w-48">
                                    <div class="tw-flex">
                                        <input
                                            type="number"
                                            class="tw-border tw-border-r-0 tw-rounded-r-none tw-rounded tw-py-2 tw-px-3 tw-outline-none tw-w-full"
                                            :placeholder="
                                                $t(
                                                    'globalDriverFee.standardVipDeliveryFee'
                                                )
                                            "
                                            :disabled="model.is_global_fee"
                                            v-model.number="
                                                l.standard_vip_delivery_fee
                                            "
                                        />
                                        <select
                                            v-model="
                                                l.standard_vip_delivery_fee_type
                                            "
                                            :disabled="model.is_global_fee"
                                            class="tw-w-12 tw-border tw-border-l-0 tw-rounded-r tw-outline-none"
                                        >
                                            <option value="$">$</option>
                                            <option value="%">%</option>
                                        </select>
                                    </div>
                                    <div
                                        class="tw-text-red-600 tw-text-xs"
                                        v-if="
                                            errors.has(
                                                'item_list.' +
                                                    index +
                                                    '.standard_vip_delivery_fee'
                                            )
                                        "
                                    >
                                        {{
                                            errors.first(
                                                'item_list.' +
                                                    index +
                                                    '.standard_vip_delivery_fee'
                                            )
                                        }}
                                    </div>
                                </td>
                                <td class="tw-px-3 tw-py-2 tw-w-48">
                                    <div class="tw-flex">
                                        <input
                                            type="number"
                                            class="tw-border tw-border-r-0 tw-rounded-r-none tw-rounded tw-py-2 tw-px-3 tw-outline-none tw-w-full"
                                            :placeholder="
                                                $t(
                                                    'globalDriverFee.standardNormalDeliveryFee'
                                                )
                                            "
                                            :disabled="model.is_global_fee"
                                            v-model.number="
                                                l.standard_normal_delivery_fee
                                            "
                                        />
                                        <select
                                            v-model="
                                                l.standard_normal_delivery_fee_type
                                            "
                                            :disabled="model.is_global_fee"
                                            class="tw-w-14 tw-border tw-border-l-0 tw-rounded-r tw-outline-none tw-text-center"
                                        >
                                            <option value="$">$</option>
                                            <option value="%">%</option>
                                        </select>
                                    </div>
                                    <div
                                        class="tw-text-red-600 tw-text-xs"
                                        v-if="
                                            errors.has(
                                                'item_list.' +
                                                    index +
                                                    '.standard_normal_delivery_fee'
                                            )
                                        "
                                    >
                                        {{
                                            errors.first(
                                                'item_list.' +
                                                    index +
                                                    '.standard_normal_delivery_fee'
                                            )
                                        }}
                                    </div>
                                </td>
                                <td
                                    class="tw-w-4 tw-px-3 tw-py-2 tw-text-center"
                                    v-if="!model.is_global_fee"
                                >
                                    <a-popconfirm
                                        v-if="
                                            $can('delete-exchange-rates') &&
                                            !l.is_deleted
                                        "
                                        placement="leftTop"
                                        :title="$t('are_you_sure_to_delete')"
                                        :ok-text="$t('yes')"
                                        :cancel-text="$t('no')"
                                        @confirm="removeRow(index, l)"
                                    >
                                        <a href="#" :disabled="l._deleting">
                                            <i
                                                class="fas fa-circle-notch spin"
                                                v-if="l._deleting"
                                            ></i>
                                            <i
                                                class="fa fa-trash text-red"
                                                v-else
                                            ></i>
                                        </a>
                                    </a-popconfirm>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <!-- <div class="col-md-5 col-sm-12 tw-space-y-1">
                    <label>{{
                        $t("driverProfile.standard")
                    }}</label>
										<div class="tw-border tw-rounded-md tw-p-3 tw-space-y-3">
												<div class="tw-grid tw-grid-cols-3 tw-gap-3">
													<div class="md:tw-text-right tw-text-left required">{{ $t("driverProfile.vipDeliveryFee") }} : </div>
													<div class="tw-col-span-2">
														<div class="tw-flex">
															<input type="number" 
																class="tw-border tw-border-r-0 tw-rounded-r-none tw-rounded tw-py-1 tw-px-3 tw-outline-none tw-w-full"
																:placeholder="$t('driverProfile.vipDeliveryFee')"
																v-model.number="model.standard_vip_delivery_fee"
															>
															<select
																v-model="model.standard_vip_delivery_fee_type" 
																class="tw-w-12 tw-border tw-border-l-0 tw-rounded-r tw-outline-none">
																<option value="$">$</option>
																<option value="%">%</option>
															</select>
														</div>
														<div class="tw-text-red-600" v-if="errors.has('standard_vip_delivery_fee')">
															{{ errors.first("standard_vip_delivery_fee") }}
														</div>
													</div>
												</div>

												<div class="tw-grid tw-grid-cols-3 tw-gap-3">
													<div class="md:tw-text-right tw-text-left required">{{ $t("driverProfile.normalDeliveryFee") }} : </div>
													<div class="tw-col-span-2">
														<div class="tw-flex">
															<input type="number" 
																class="tw-border tw-border-r-0 tw-rounded-r-none tw-rounded tw-py-1 tw-px-3 tw-outline-none tw-w-full"
																:placeholder="$t('driverProfile.normalDeliveryFee')"
																v-model.number="model.standard_normal_delivery_fee"
															>
															<select
																v-model="model.standard_normal_delivery_fee_type" 
																class="tw-w-12 tw-border tw-border-l-0 tw-rounded-r tw-outline-none">
																<option value="$">$</option>
																<option value="%">%</option>
															</select>
														</div>
														<div class="tw-text-red-600" v-if="errors.has('standard_normal_delivery_fee')">
															{{ errors.first("standard_normal_delivery_fee") }}
														</div>														
													</div>
												</div>
										</div>
                </div>
                <div class="col-md-5 col-sm-12 tw-space-y-1">
                    <label>{{ $t("driverProfile.express") }}</label>
										<div class="tw-border tw-rounded-md tw-p-3 tw-space-y-3">
												<div class="tw-grid tw-grid-cols-3 tw-gap-3">
													<div class="md:tw-text-right tw-text-left required">{{ $t("driverProfile.vipDeliveryFee") }} : </div>
													<div class="tw-col-span-2">
														<div class="tw-flex">
															<input type="number" 
																class="tw-border tw-border-r-0 tw-rounded-r-none tw-rounded tw-py-1 tw-px-3 tw-outline-none tw-w-full"
																:placeholder="$t('driverProfile.vipDeliveryFee')"
																v-model.number="model.express_vip_delivery_fee"
															>
															<select
																v-model="model.express_vip_delivery_fee_type" 
																class="tw-w-12 tw-border tw-border-l-0 tw-rounded-r tw-outline-none">
																<option value="$">$</option>
																<option value="%">%</option>
															</select>
														</div>
														<div class="tw-text-red-600" v-if="errors.has('express_vip_delivery_fee')">
															{{ errors.first("express_vip_delivery_fee") }}
														</div>	
													</div>
												</div>
												<div class="tw-grid tw-grid-cols-3 tw-gap-3">
													<div class="md:tw-text-right tw-text-left required">{{ $t("driverProfile.normalDeliveryFee") }} : </div>
													<div class="tw-col-span-2">
														<div class="tw-flex">
															<input type="number" 
																class="tw-border tw-border-r-0 tw-rounded-r-none tw-rounded tw-py-1 tw-px-3 tw-outline-none tw-w-full"
																:placeholder="$t('driverProfile.normalDeliveryFee')"
																v-model.number="model.express_normal_delivery_fee"
															>
															<select
																v-model="model.express_normal_delivery_fee_type" 
																class="tw-w-12 tw-border tw-border-l-0 tw-rounded-r tw-outline-none">
																<option value="$">$</option>
																<option value="%">%</option>
															</select>
														</div>
														<div class="tw-text-red-600" v-if="errors.has('express_normal_delivery_fee')">
															{{ errors.first("express_normal_delivery_fee") }}
														</div>														
													</div>
												</div>
										</div>
                </div> -->
            </div>
        </ts-panel-wrapper>
    </ts-panel>
</template>

<script>
import { Errors } from 'form-backend-validation'
import { mapActions, mapState } from 'vuex'
import { isEmpty } from 'lodash'
export default {
    name: 'driverFee',
    props: ['value', 'validate'],
    data() {
        return {
            errors: new Errors(),
            model: {}
        }
    },
    created() {
        this.model = this.value
        this.fetchFormView({
            params: { fnName: 'globalDriverFee' },
            index: 'globalDriverFee'
        })
    },
    computed: {
        ...mapState('driver/driverProfile', ['formModels', 'edit_data']),
        isUpdate() {
            return !isEmpty(this.edit_data)
        }
    },
    methods: {
        ...mapActions('driver/driverProfile', ['getFormViewData']),
        async fetchFormView(params) {
            this.loading = true
            await this.getFormViewData({ params: params })
                .catch(err => {
                    this.$notify({ type: 'error', text: err.message })
                })
                .finally(() => {
                    this.loading = false
                })
            this.isGlobalFee()
        },
        addRow() {
            this.model.driver_fees.push({
                id: null,
                num_package_from: '',
                num_package_to: '',
                express_vip_delivery_fee: '',
                express_vip_delivery_fee_type: '$',
                express_normal_delivery_fee: '',
                express_normal_delivery_fee_type: '$',
                standard_vip_delivery_fee: '',
                standard_vip_delivery_fee_type: '$',
                standard_normal_delivery_fee: '',
                standard_normal_delivery_fee_type: '$'
            })
        },
        removeRow(index, record) {
            if (record.id == null) {
                this.model.driver_fees.splice(index, 1)
            } else {
                record._deleting = true
                this.$store
                    .dispatch('driver/driverProfile/destroy', record.id)
                    .then(response => {
                        this.$notify({
                            type: 'success',
                            text: response.message
                        })
                        this.model.driver_fees.splice(index, 1)
                    })
                    .catch(error => {
                        this.$toasted.error(error.message)
                    })
                    .finally(() => {
                        record._deleting = false
                    })
            }
        },
        isGlobalFee() {
            this.model.driver_fees = []
            if (this.model.is_global_fee == true) {
                this.model.driver_fees = this.formModels.globalDriverFee
            } else {
                if (this.isUpdate) {
                    if (this.edit_data.driver_fees.length > 0) {
                        this.model.driver_fees = this.edit_data.driver_fees
                    } else {
                        this.formModels.globalDriverFee.map(el =>
                            this.model.driver_fees.push({
                                id: null,
                                express_normal_delivery_fee:
                                    el.express_normal_delivery_fee,
                                express_normal_delivery_fee_type:
                                    el.express_normal_delivery_fee_type,
                                express_vip_delivery_fee:
                                    el.express_vip_delivery_fee,
                                express_vip_delivery_fee_type:
                                    el.express_vip_delivery_fee_type,
                                num_package_from: el.num_package_from,
                                num_package_to: el.num_package_to,
                                standard_normal_delivery_fee:
                                    el.standard_normal_delivery_fee,
                                standard_normal_delivery_fee_type:
                                    el.standard_normal_delivery_fee_type,
                                standard_vip_delivery_fee:
                                    el.standard_vip_delivery_fee,
                                standard_vip_delivery_fee_type:
                                    el.standard_vip_delivery_fee_type
                            })
                        )
                    }
                } else {
                    this.formModels.globalDriverFee.map(el =>
                        this.model.driver_fees.push({
                            id: null,
                            express_normal_delivery_fee:
                                el.express_normal_delivery_fee,
                            express_normal_delivery_fee_type:
                                el.express_normal_delivery_fee_type,
                            express_vip_delivery_fee:
                                el.express_vip_delivery_fee,
                            express_vip_delivery_fee_type:
                                el.express_vip_delivery_fee_type,
                            num_package_from: el.num_package_from,
                            num_package_to: el.num_package_to,
                            standard_normal_delivery_fee:
                                el.standard_normal_delivery_fee,
                            standard_normal_delivery_fee_type:
                                el.standard_normal_delivery_fee_type,
                            standard_vip_delivery_fee:
                                el.standard_vip_delivery_fee,
                            standard_vip_delivery_fee_type:
                                el.standard_vip_delivery_fee_type
                        })
                    )
                }
            }
        },
        deleteRecord(record) {
            record._deleting = true
            this.$store
                .dispatch('driver/driverProfile/destroy', record.id)
                .then(response => {
                    this.$notify({ type: 'success', text: response.message })
                    this.fetchData()
                })
                .catch(error => {
                    this.$toasted.error(error.message)
                })
                .finally(() => {
                    record._deleting = false
                })
        }
    },
    watch: {
        validate: function (error) {
            this.errors = new Errors(error.errors)
        },
        'model.is_global_fee': function () {
            this.isGlobalFee()
        }
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.isGlobalFee()
        })
    }
}
</script>

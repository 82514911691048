<template>
    <ts-panel>
        <ts-panel-wrapper class="tw-space-y-2">
            <h4
                class="tw-mb-0 tw-p-1 tw-uppercase tw-font-semibold tw-text-gray-400 tw-underline"
            >
                {{ $t("driverProfile.currentAddress") }}
            </h4>
            <div class="row tw-space-y-2 sm:tw-space-y-0">
                <div class="col-md-6 tw-space-y-1">
                    <label class="required">{{
                        $t("driverProfile.province")
                    }}</label>
                    <a-select
                        v-model="model.province_code"
                        :options="provinceList"
                        @change="onProvinceChange"
                        showSearch
                        :filterOption="
                            (input, option) =>
                                option.componentOptions.children[0].text
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                        "
                        class="tw-w-full"
                        :placeholder="$t('driverProfile.province')"
                    ></a-select>
                    <div
                        class="tw-text-red-600"
                        v-if="errors.has('province_code')"
                    >
                        {{ errors.first("province_code") }}
                    </div>
                </div>
                <div class="col-md-6 tw-space-y-1">
                    <label class="required">{{
                        $t("driverProfile.district")
                    }}</label>
                    <a-select
                        v-model="model.district_code"
                        :options="districtList"
                        @change="onDistrictChange"
                        showSearch
                        :filterOption="
                            (input, option) =>
                                option.componentOptions.children[0].text
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                        "
                        class="tw-w-full"
                        :placeholder="$t('driverProfile.district')"
                    ></a-select>
                    <div
                        class="tw-text-red-600"
                        v-if="errors.has('district_code')"
                    >
                        {{ errors.first("district_code") }}
                    </div>
                </div>
            </div>
            <div class="row tw-space-y-2 sm:tw-space-y-0">
                <div class="col-md-6 tw-space-y-1">
                    <label class="required">{{
                        $t("driverProfile.commune")
                    }}</label>
                    <a-select
                        v-model="model.commune_code"
                        :options="communeList"
                        @change="onCommuneChange"
                        showSearch
                        :filterOption="
                            (input, option) =>
                                option.componentOptions.children[0].text
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                        "
                        class="tw-w-full"
                        :placeholder="$t('driverProfile.commune')"
                    ></a-select>
                    <div
                        class="tw-text-red-600"
                        v-if="errors.has('commune_code')"
                    >
                        {{ errors.first("commune_code") }}
                    </div>
                </div>
                <div class="col-md-6 tw-space-y-1">
                    <label class="required">{{
                        $t("driverProfile.village")
                    }}</label>
                    <a-select
                        v-model="model.village_code"
                        :options="villageList"
                        showSearch
                        :filterOption="
                            (input, option) =>
                                option.componentOptions.children[0].text
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                        "
                        class="tw-w-full"
                        :placeholder="$t('driverProfile.village')"
                    ></a-select>
                    <div
                        class="tw-text-red-600"
                        v-if="errors.has('village_code')"
                    >
                        {{ errors.first("village_code") }}
                    </div>
                </div>
            </div>
        </ts-panel-wrapper>
    </ts-panel>
</template>

<script>
import { Errors } from "form-backend-validation";
import { mapActions, mapState } from "vuex";
export default {
    name: "currentAddress",
    props: ["value", "validate"],
    data() {
        return {
            errors: new Errors(),
            model: {}
        };
    },
    created() {
        this.model = this.value;
    },
    computed: {
        ...mapState("driver/driverProfile", ["formModels"]),
        provinceList() {
            if (!this.formModels.provinces) return [];

            return this.formModels.provinces.map(el => ({
                value: el.province_code,
                label: el.name_en
            }));
        },
        districtList() {
            if (!this.formModels.districts) return [];

            return this.formModels.districts.map(el => ({
                value: el.district_code,
                label: el.name_en
            }));
        },
        communeList() {
            if (!this.formModels.communes) return [];

            return this.formModels.communes.map(el => ({
                value: el.commune_code,
                label: el.name_en
            }));
        },
        villageList() {
            if (!this.formModels.villages) return [];

            return this.formModels.villages.map(el => ({
                value: el.village_code,
                label: el.name_en
            }));
        }
    },
    methods: {
        ...mapActions("driver/driverProfile", ["getFormViewData"]),
        fetchFormView({ params, index }) {
            this.$emit("loading", true);
            this.getFormViewData({ params: params, index: index })
                .catch(err => {
                    this.$notify({ type: "error", text: err.message });
                })
                .finally(() => {
                    this.$emit("loading", false);
                });
        },
        onProvinceChange(value) {
            this.model.district_code = undefined;
            this.model.commune_code = undefined;
            this.model.village_code = undefined;
            this.fetchFormView({
                index: "districts",
                params: {
                    provinceCode: value,
                    fnName: "districts"
                }
            });
        },
        onDistrictChange(value) {
            this.model.commune_code = undefined;
            this.model.village_code = undefined;
            this.fetchFormView({
                index: "communes",
                params: {
                    districtCode: value,
                    communeCode: "",
                    fnName: "communes"
                }
            });
        },
        onCommuneChange(value) {
            this.model.village_code = undefined;
            this.fetchFormView({
                index: "villages",
                params: {
                    communeCode: value,
                    fnName: "villages"
                }
            });
        }
    },
    watch: {
        validate: function(error) {
            this.errors = new Errors(error.errors);
        }
    }
};
</script>

<template>
    <div class="tw-space-y-2">
        <h4
            class="tw-mb-0 tw-p-1 tw-uppercase tw-font-semibold tw-text-gray-400 tw-underline"
        >
            {{ $t("driverProfile.personalInfo") }}
        </h4>
        <div class="tw-flex tw-space-x-3 tw-w-full">
            <div class="tw-text-center tw-w-52 tw-h-28">
                <UploadImage v-model="model.photo" ref="uploadimage" style="height: 124px;" />
            </div>
            <div class="tw-w-full">
                <div class="row">
                    <div class="col-md-12 tw-space-y-2">
                        <div class="row">
                            <div class="col-md-8 col-sm-6 tw-space-y-2">
                                <div class="tw-space-y-1">
                                    <label class="required">{{
                                        $t("driverProfile.driverName")
                                    }}</label>
                                    <input
																				v-model="model.driver_name"
                                        type="text"
                                        class="form-control"
																				:class="{ 'is-invalid': errors.has('driver_name') }"
                                        :placeholder="
                                            $t('driverProfile.driverName')
                                        "
                                    />
																		<div class="invalid-feedback" v-if="errors.has('driver_name')">
																			{{ errors.first('driver_name') }}
																		</div>
                                </div>
                                <div class="tw-space-y-1">
                                    <label class="required">{{
                                        $t("driverProfile.dateOfBirth")
                                    }}</label>
                                    <a-date-picker
																				v-model="model.dob"
                                        class="tw-w-full"
                                        format="DD-MM-YYYY"
                                        valueFormat="DD-MM-YYYY"
																				:placeholder="$t('driverProfile.dateOfBirth')"
                                    ></a-date-picker>
																		<div class="tw-text-red-600" v-if="errors.has('dob')">
																			{{ errors.first('dob') }}
																		</div>
                                </div>
                            </div>
                            <div class="col-md-4 col-sm-6">
                                <div>
                                    <label class="required">{{
                                        $t("driverProfile.sex")
                                    }}</label>
                                    <a-radio-group
																				v-model="model.sex"
                                        name="sex"
                                        class="tw-w-full tw-space-y-2"
                                    >
                                        <a-radio value="M">
                                            {{ $t("driverProfile.male") }}
                                        </a-radio>
                                        <a-radio value="F">
                                            {{ $t("driverProfile.female") }}
                                        </a-radio>
                                    </a-radio-group>
																		<div class="tw-text-red-600" v-if="errors.has('sex')">
																			{{ errors.first('sex') }}
																		</div>
                                </div>
                                <div class="tw-mt-2 tw-space-y-2">
                                    <ts-checkbox
																			v-model="model.is_internal_driver"
																		>{{
                                        $t("driverProfile.internalDriver")
                                    }}</ts-checkbox>
                                    <ts-checkbox
																			v-model="model.is_active"
																		>{{
                                        $t("driverProfile.active")
                                    }}</ts-checkbox>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row tw-space-y-2 sm:tw-space-y-0">
            <div class="col-md-6 tw-space-y-1">
                <label class="required">{{ $t("driverProfile.phone1") }}</label>
                <input
										v-model="model.phone1"
                    type="text"
                    class="form-control"
										:class="{ 'is-invalid': errors.has('phone1') }"
                    :placeholder="$t('driverProfile.phone1')"
                />
								<div class="invalid-feedback" v-if="errors.has('phone1')">
									{{ errors.first('phone1') }}
								</div>
            </div>
            <div class="col-md-6 tw-space-y-1">
              <div class="row">
                <div class="col-md-6">
                  <label>{{ $t("driverProfile.phone2") }}</label>
                  <input
                      v-model="model.phone2"
                      type="text"
                      class="form-control"
                      :class="{ 'is-invalid': errors.has('phone2') }"
                      :placeholder="$t('driverProfile.phone2')"
                  />
                  <div class="invalid-feedback" v-if="errors.has('phone2')">
                    {{ errors.first('phone2') }}
                  </div>								
                </div>
                <div class="col-md-6 tw-space-y-1">
                  <label>{{ $t("driverProfile.phone3") }}</label>
                  <input
                      v-model="model.phone3"
                      type="text"
                      class="form-control"
                      :class="{ 'is-invalid': errors.has('phone3') }"
                      :placeholder="$t('driverProfile.phone3')"
                    />
                    <div class="invalid-feedback" v-if="errors.has('phone3')">
                      {{ errors.first('phone3') }}
                    </div>								
                </div>
              </div>
            </div>
        </div>
        <div class="row tw-space-y-2 sm:tw-space-y-0">
            <div class="col-md-6 tw-space-y-1">
                <label>{{
                    $t("driverProfile.emergencyContact")
                }}</label>
                <input
										v-model="model.emergency_contact"
                    type="text"
                    class="form-control"
										:class="{ 'is-invalid': errors.has('emergency_contact') }"
                    :placeholder="$t('driverProfile.emergencyContact')"
                />
								<div class="invalid-feedback" v-if="errors.has('emergency_contact')">
									{{ errors.first('emergency_contact') }}
								</div>										
            </div>
            <div class="col-md-6 tw-space-y-1">
                <label>{{
                    $t("driverProfile.emergencyRelation")
                }}</label>
                <input
										v-model="model.emergency_relationship"
                    type="text"
                    class="form-control"
										:class="{ 'is-invalid': errors.has('emergency_relationship') }"
                    :placeholder="$t('driverProfile.emergencyRelation')"
                />
								<div class="invalid-feedback" v-if="errors.has('emergency_relationship')">
									{{ errors.first('emergency_relationship') }}
								</div>										
            </div>
        </div>
        <div class="row tw-space-y-2 sm:tw-space-y-0">
            <div class="col-md-6 tw-space-y-1">
                <label class="required">{{
                    $t("driverProfile.contractStartDate")
                }}</label>
                <a-date-picker
										v-model="model.contract_start_date"
                    class="tw-w-full"
										format="DD-MM-YYYY"
										valueFormat="DD-MM-YYYY"
                    :placeholder="$t('driverProfile.contractStartDate')"
                ></a-date-picker>
								<div class="tw-text-red-600" v-if="errors.has('contract_start_date')">
									{{ errors.first('contract_start_date') }}
								</div>		
            </div>
            <div class="col-md-6 tw-space-y-1">
                <label class="required">{{
                    $t("driverProfile.contractEndDate")
                }}</label>
                <a-date-picker
										v-model="model.contract_end_date"
										format="DD-MM-YYYY"
										valueFormat="DD-MM-YYYY"
                    class="tw-w-full"
                    :placeholder="$t('driverProfile.contractEndDate')"
                ></a-date-picker>
								<div class="tw-text-red-600" v-if="errors.has('contract_end_date')">
									{{ errors.first('contract_end_date') }}
								</div>	
            </div>
        </div>
    </div>
</template>

<script>
import { Errors } from 'form-backend-validation'
import UploadImage from "./upload-image.vue";
export default {
	name: 'personalInfo',
	props: ['value', 'validate'],
	components: {
		UploadImage,
	},
	data() {
		return {
			errors: new Errors(),
			model: {}
		}
	},
	created() {
			this.model = this.value;
	},
	watch: {
			validate: function(error) {
					this.errors = new Errors(error.errors);
			}
	}
};
</script>

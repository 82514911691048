<template>
    <div class="tw-space-y-2">
        <h4
            class="tw-mt-3 tw-mb-0 tw-p-1 tw-uppercase tw-font-semibold tw-text-gray-400 tw-underline"
        >
            {{ $t("driverProfile.idOrPassport") }}
        </h4>

        <div class="row tw-space-y-2 sm:tw-space-y-0">
            <div class="col-md-6 tw-space-y-1">
                <label class="required">{{
                    $t("driverProfile.idPassportNo")
                }}</label>
                <input
										v-model="model.id_passport_no"
                    type="text"
                    class="form-control"
										:class="{ 'is-invalid': errors.has('id_passport_no') }"
                    :placeholder="$t('driverProfile.idPassportNo')"
                />
								<div class="invalid-feedback" v-if="errors.has('id_passport_no')">
									{{ errors.first('id_passport_no') }}
								</div>
            </div>
            <div class="col-md-6 tw-space-y-1">
                <label class="required">{{
                    $t("driverProfile.idPassportIssueDate")
                }}</label>
                <a-date-picker
										v-model="model.id_passport_issue_date"
                    class="tw-w-full"
										format="DD-MM-YYYY"
										valueFormat="DD-MM-YYYY"
                    :placeholder="$t('driverProfile.idPassportIssueDate')"
                ></a-date-picker>
								<div class="tw-text-red-600" v-if="errors.has('id_passport_issue_date')">
									{{ errors.first('id_passport_issue_date') }}
								</div>							
            </div>
        </div>
        <div class="row tw-space-y-2 sm:tw-space-y-0">
            <div class="col-md-12 tw-space-y-1">
                <label class="required">{{
                    $t("driverProfile.idPassportExpireDate")
                }}</label>
                <a-date-picker
										v-model="model.id_passport_expiry_date"
                    class="tw-w-full"
										format="DD-MM-YYYY"
										valueFormat="DD-MM-YYYY"
                    :placeholder="$t('driverProfile.idPassportExpireDate')"
                ></a-date-picker>
								<div class="tw-text-red-600" v-if="errors.has('id_passport_expiry_date')">
									{{ errors.first('id_passport_expiry_date') }}
								</div>									
            </div>
        </div>
    </div>
</template>

<script>
import { Errors } from "form-backend-validation";
export default {
    name: "idOrPassport",
    props: ["value", "validate"],
    data() {
        return {
            errors: new Errors(),
            model: {}
        };
    },
    created() {
        this.model = this.value;
    },
    watch: {
        validate: function(error) {
            this.errors = new Errors(error.errors);
        }
    }
};
</script>

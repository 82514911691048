<template>
    <ts-panel>
        <ts-panel-wrapper class="tw-space-y-2">
            <h4
                class="tw-mb-0 tw-p-1 tw-uppercase tw-font-semibold tw-text-gray-400 tw-underline"
            >
                {{ $t("driverProfile.placeOfBirth") }}
            </h4>
            <div class="row tw-space-y-2 sm:tw-space-y-0">
                <div class="col-md-6 tw-space-y-1">
                    <label>{{ $t("driverProfile.province") }}</label>
                    <a-select
                        v-model="model.pob_province_code"
                        :options="provinceList"
                        showSearch
                        :filterOption="
                            (input, option) =>
                                option.componentOptions.children[0].text
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                        "
                        class="tw-w-full"
                        :placeholder="$t('driverProfile.province')"
                        @change="onProvinceChange"
                    ></a-select>
                    <div
                        class="tw-text-red-600"
                        v-if="errors.has('pob_province_code')"
                    >
                        {{ errors.first("pob_province_code") }}
                    </div>
                </div>
                <div class="col-md-6 tw-space-y-1">
                    <label>{{ $t("driverProfile.district") }}</label>
                    <a-select
                        v-model="model.pob_district_code"
                        :options="districtList"
                        showSearch
                        :filterOption="
                            (input, option) =>
                                option.componentOptions.children[0].text
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                        "
                        class="tw-w-full"
                        :placeholder="$t('driverProfile.district')"
                        @change="onDistrictChange"
                    ></a-select>
                    <div
                        class="tw-text-red-600"
                        v-if="errors.has('pob_district_code')"
                    >
                        {{ errors.first("pob_district_code") }}
                    </div>
                </div>
            </div>
            <div class="row tw-space-y-2 sm:tw-space-y-0">
                <div class="col-md-6 tw-space-y-1">
                    <label>{{ $t("driverProfile.commune") }}</label>
                    <a-select
                        v-model="model.pob_commune_code"
                        :options="communeList"
                        @change="onCommuneChange"
                        showSearch
                        :filterOption="
                            (input, option) =>
                                option.componentOptions.children[0].text
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                        "
                        class="tw-w-full"
                        :placeholder="$t('driverProfile.commune')"
                    ></a-select>
                    <div
                        class="tw-text-red-600"
                        v-if="errors.has('pob_commune_code')"
                    >
                        {{ errors.first("pob_commune_code") }}
                    </div>
                </div>
                <div class="col-md-6 tw-space-y-1">
                    <label>{{ $t("driverProfile.village") }}</label>
                    <a-select
                        v-model="model.pob_village_code"
                        :options="villageList"
                        showSearch
                        :filterOption="
                            (input, option) =>
                                option.componentOptions.children[0].text
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                        "
                        class="tw-w-full"
                        :placeholder="$t('driverProfile.village')"
                    ></a-select>
                    <div
                        class="tw-text-red-600"
                        v-if="errors.has('pob_village_code')"
                    >
                        {{ errors.first("pob_village_code") }}
                    </div>
                </div>
            </div>
        </ts-panel-wrapper>
    </ts-panel>
</template>

<script>
import { Errors } from "form-backend-validation";
import { mapActions, mapState } from "vuex";
export default {
    name: "placeOfBirth",
    props: ["value", "validate"],
    data() {
        return {
            errors: new Errors(),
            model: {}
        };
    },
    created() {
        this.model = this.value;
    },
    computed: {
        ...mapState("driver/driverProfile", ["formModels"]),
        provinceList() {
            if (!this.formModels.provinces) return [];

            return this.formModels.provinces.map(el => ({
                value: el.province_code,
                label: el.name_en
            }));
        },
        districtList() {
            if (!this.formModels.pDistricts) return [];

            return this.formModels.pDistricts.map(el => ({
                value: el.district_code,
                label: el.name_en
            }));
        },
        communeList() {
            if (!this.formModels.pCommunes) return [];

            return this.formModels.pCommunes.map(el => ({
                value: el.commune_code,
                label: el.name_en
            }));
        },
        villageList() {
            if (!this.formModels.pVillages) return [];

            return this.formModels.pVillages.map(el => ({
                value: el.village_code,
                label: el.name_en
            }));
        }
    },
    methods: {
        ...mapActions("driver/driverProfile", ["getFormViewData"]),
        fetchFormView({ params, index }) {
            this.$emit("loading", true);
            this.getFormViewData({ params: params, index: index })
                .catch(err => {
                    this.$notify({ type: "error", text: err.message });
                })
                .finally(() => {
                    this.$emit("loading", false);
                });
        },
        onProvinceChange(value) {
            this.model.pob_district_code = undefined;
            this.model.pob_commune_code = undefined;
            this.model.pob_village_code = undefined;
            this.fetchFormView({
								index: 'pDistricts',
                params: {
                    pprovinceCode: value,
                    fnName: "pDistricts"
                }
            });
        },
        onDistrictChange(value) {
            this.model.pob_commune_code = undefined;
            this.model.pob_village_code = undefined;
            this.fetchFormView({
								index: 'pCommunes',
                params: {
                    pdistrictCode: value,
                    communeCode: "",
                    fnName: "pCommunes"
                }
            });
        },
        onCommuneChange(value) {
            this.model.pob_village_code = undefined;
            this.fetchFormView({
								index: 'pVillages',
                params: {
                    pcommuneCode: value,
                    fnName: "pVillages"
                }
            });
        }
    },
    watch: {
        validate: function(error) {
            this.errors = new Errors(error.errors);
        }
    }
};
</script>

<template>
    <ts-panel>
        <ts-panel-wrapper class="tw-space-y-2">
            <h4
                class="tw-mb-0 tw-p-1 tw-uppercase tw-font-semibold tw-text-gray-400 tw-underline"
            >
                {{ $t("driverProfile.schedule") }}
            </h4>
            <table class="tw-w-full" border="1">
                <thead>
                    <tr>
                        <th class="tw-uppercase tw-w-32 tw-px-3">
                            {{ $t("driverProfile.mon") }}
                        </th>
                        <th class="tw-uppercase tw-w-32 tw-px-3">
                            {{ $t("driverProfile.tue") }}
                        </th>
                        <th class="tw-uppercase tw-w-32 tw-px-3">
                            {{ $t("driverProfile.wed") }}
                        </th>
                        <th class="tw-uppercase tw-w-32 tw-px-3">
                            {{ $t("driverProfile.thu") }}
                        </th>
                        <th class="tw-uppercase tw-w-32 tw-px-3">
                            {{ $t("driverProfile.fri") }}
                        </th>
                        <th class="tw-uppercase tw-w-32 tw-px-3">
                            {{ $t("driverProfile.sat") }}
                        </th>
                        <th class="tw-uppercase tw-w-32 tw-px-3">
                            {{ $t("driverProfile.sun") }}
                        </th>
                        <th class="tw-uppercase tw-px-3 required">
                            {{ $t("driverProfile.startTime") }}
                        </th>
                        <th class="tw-uppercase tw-px-3 required">
                            {{ $t("driverProfile.leaveTime") }}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td class="tw-uppercase tw-w-32 tw-px-3">
                            <ts-checkbox
                                v-model="model.sch_monday"
                            ></ts-checkbox>
                        </td>
                        <td class="tw-uppercase tw-w-32 tw-px-3">
                            <ts-checkbox
                                v-model="model.sch_tuesday"
                            ></ts-checkbox>
                        </td>
                        <td class="tw-uppercase tw-w-32 tw-px-3">
                            <ts-checkbox
                                v-model="model.sch_wednesday"
                            ></ts-checkbox>
                        </td>
                        <td class="tw-uppercase tw-w-32 tw-px-3">
                            <ts-checkbox
                                v-model="model.sch_thursday"
                            ></ts-checkbox>
                        </td>
                        <td class="tw-uppercase tw-w-32 tw-px-3">
                            <ts-checkbox
                                v-model="model.sch_friday"
                            ></ts-checkbox>
                        </td>
                        <td class="tw-uppercase tw-w-32 tw-px-3">
                            <ts-checkbox
                                v-model="model.sch_saturday"
                            ></ts-checkbox>
                        </td>
                        <td class="tw-uppercase tw-w-32 tw-px-3">
                            <ts-checkbox
                                v-model="model.sch_sunday"
                            ></ts-checkbox>
                        </td>
                        <td class="tw-px-3">
                            <div class="input-group date">
                                <date-picker
                                    id="timeInput"
                                    :config="{
                                        format: 'LT',
                                        useCurrent: false
                                    }"
                                    v-model="model.sch_start_time"
                                    :placeholder="$t('driverProfile.startTime')"
                                ></date-picker>
                                <label for="timeInput" class="input-group-text">
                                    <i class="fa fa-clock"></i>
                                </label>
                            </div>
                            <!-- <a-time-picker
                                v-model="model.sch_start_time"
                                style="width:100%"
                                use12-hours
                                format="h:mm:ss A"
                                valueFormat="h:mm:ss A"
                            /> -->
                            <div
                                class="tw-text-red-600"
                                v-if="errors.has('sch_start_time')"
                            >
                                {{ errors.first("sch_start_time") }}
                            </div>
                        </td>
                        <td class="tw-px-3">
                            <div class="input-group date">
                                <date-picker
                                    id="timeInput"
                                    :config="{
                                        format: 'LT',
                                        useCurrent: false
                                    }"
                                    v-model="model.sch_stop_time"
                                    :placeholder="$t('driverProfile.leaveTime')"
                                ></date-picker>
                                <label for="timeInput" class="input-group-text">
                                    <i class="fa fa-clock"></i>
                                </label>
                            </div>
                            <!-- <a-time-picker
                                v-model="model.sch_stop_time"
                                style="width:100%"
                                use12-hours
                                format="h:mm:ss A"
                                valueFormat="h:mm:ss A"
                            /> -->
                            <div
                                class="tw-text-red-600"
                                v-if="errors.has('sch_stop_time')"
                            >
                                {{ errors.first("sch_stop_time") }}
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </ts-panel-wrapper>
    </ts-panel>
</template>

<script>
import { Errors } from "form-backend-validation";

export default {
    name: "scheduleDriver",
    props: ["value", "validate"],
    data() {
        return {
            errors: new Errors(),
            model: {}
        };
    },
    created() {
        this.model = this.value;
    },
    watch: {
        validate: function(error) {
            this.errors = new Errors(error.errors);
        }
    }
};
</script>
